import {
  Box,
  Grid,
  Td,
  Switch,
  TableContainer,
  Th,
  Table,
  Tbody,
  Thead,
  Tr,
  GridItem,
  Button,
  Text
} from "@chakra-ui/react";
import React, { useRef } from "react";
import { FaPrint } from "react-icons/fa";
import { useReactToPrint } from "react-to-print";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  LabelList,
  ResponsiveContainer,
  ComposedChart,
  Line,
  Legend,
  Tooltip
} from "recharts";

export interface Props {
  cylinderName: string;
  cylinderVolume: string;
  cylinderMinMCSRecVolume: string;
  immersionHeaterPower: string;
  cylinderCoilSize: string;
  validEPCNumber: string;
  epcSpaceHeatingRequirement: number;
  epcSpaceHeatingRequirementOutputsC6: number;
  epcHotWaterRequirement: number;
  epcHotWaterRequirementOutputsC7: number;
  numOfBedrooms: number;
  numOfPeopleInProperty: number;
  locationForDesignTemp: string;
  altitude: string;
  distanceToAssessmentPoint?: number;
  surfacesFromUnit: number;
  shieldingOfASHP: string;
  hotWaterRequiredPerDay: {mcs: number; genous: number};
  targetTempInLivingArea: {mcs: number; genous: number};
  designTempChoice: {mcs: number; genous: number};
  designTempAmbient: {mcs: number; genous: number};
  hotWaterCylinderTemp: {mcs: number; genous: number};
  flowTempHigh: {mcs: number; genous: number};
  flowTempLow: {mcs: number; genous: number};
  heatingSCOPEquivAtDesignTemp: {mcs: number; genous: number};
  hotWaterSCOPEquivAtDesignTemp: {mcs: number; genous: number};
  ambientTempHeatingStartsAt: {mcs: number; genous: number};
  immersionDetails: {minutesBeforeStart: number; usePerWeek: number};
  correctedSoundPressureLevels: {mcs: number; genous: string};
  roomByRoomTempApproved: {mcs: string; genous: string};
  amountOfHeatLoadHPCanProvide: {mcs: number; genous: string};
  amountAnnualHeatingRequiredHPProvide: {mcs: number; genous: string};
  amountOfAnnualHeatingHybridWillProvide: {mcs: number; genous: string};
  isHotWaterCoveredByHPORElectricAlready: {mcs: number; genous: string};
  isValidEPCPlaceConsistentMCSRules: {mcs: number; genous: string};
  // Heating Performance
  renewHeatRequiredForPropertySpaceHeating: {mcs: number; genous: number};
  renewHeatRequiredForPropertyHotWater: {mcs: number; genous: number};
  renewHeatRequiredForPropertyTotal: {mcs: number; genous: number};
  otherEnergySupplied: {mcs: number; genous: number};
  totalEnergyAssessed: {mcs: number; genous: number};
  heatedFloorArea: {mcs: number; genous: number};
  averagePeakHeatLossPerSQM: {mcs: number; genous: number};
  peakHeatDemand: {mcs: number; genous: number};
  hotWaterRecoveryTimeNoImmersion: {mcs: number; genous: number};
  hotWaterRecoveryTimeImmersion: {mcs: number; genous: number};
  energyUsedSpaceHeating: {
    mcs: {
      existing?: number;
      proposed?: number;
      diff?: number;
    }
    genous: {
      existing?: number;
      proposed?: number;
      diff?: number;
    }
  }
  energyUsedHotWater: {
    mcs: {
      existing?: number;
      proposed?: number;
      diff?: number;
    }
    genous: {
      existing?: number;
      proposed?: number;
      diff?: number;
    }
  }
  energyUsedTotal: {
    mcs: {
      existing?: number;
      proposed?: number;
      diff?: number;
    }
    genous: {
      existing?: number;
      proposed?: number;
      diff?: number;
    }
  }
  compliantInstallBus: string;
  compliantInstallNoBus: string;
  heatingSystem1: {
    manufacturer: string;
    model: string;
    sapDescription: string;
    percentHeatMCS: string;
    percentHeatGenous: string;
    percentEfficiency: string;
    fuel: string;
    fuelPricePerkWh: string;
  };
  heatingSystem2?: {
    manufacturer: string;
    model: string;
    sapDescription: string;
    percentHeatMCS: string;
    percentHeatGenous: string;
    percentEfficiency: string;
    fuel: string;
    fuelPricePerkWh: string;
  }
  secondaryHeatingSystem?: {
    manufacturer: string;
    model: string;
    sapDescription: string;
    percentHeatMCS: string;
    percentHeatGenous: string;
    percentEfficiency: string;
    fuel: string;
    fuelPricePerkWh: string;
  }
  hotWaterSystem: {
    manufacturer: string;
    model: string;
    sapDescription: string;
    percentHeatMCS: string;
    percentHeatGenous: string;
    percentEfficiency: string;
    fuel: string;
    fuelPricePerkWh: string;
  }
  heatPumpSelected: {
    manufacturer: string;
    model: string;
    mcsCert: string
  }
  hotWaterCylinderSize: string;
  capacityOfSystem: string;
  renewableHeatingCoverage: string;
  pureHPHybrid: string;
  numOfUnits: string;
  installationCostGross: number;
  boilerUpgradeScheme: number;
  netInstallationCost: number;
  timePeriodToEvaluate: number;
  annualElecPriceRiseAssumed: number;
  annualFossilFuelPriceRiseAssumed: number;
  annualRunCostSavingsEstimate: {mcs: number; genous: number};
  annualReturnPercent: {mcs: number; genous: number};
  annualCO2eReduction: {mcs: number; genous: number};
  totalPeriodRunCostSavings: {mcs: number; genous: number};
  impliedPaybackPeriod: {mcs: number; genous: number};
  totalReturnsExpenditure: {mcs: number; genous: number};
  totalPeriodCO2eAvoided: {mcs: number; genous: number};
  rooms: Array<{title: string; specificHeatLossWM2: number; existingEmitters: string; toBeRemoved: string; newEmitters: string; tempAchieved: number; agreedTempTarget: number; passFail: string}>;
  worstPerformingRoom: string;
  elecGenByMonth: {
    jan: {
      ashpSpaceHeating: number;
      hybridTopUp: number;
      secondaryHeating: number;
      hotWater: number;
    }
    feb: {
      ashpSpaceHeating: number;
      hybridTopUp: number;
      secondaryHeating: number;
      hotWater: number;
    }
    march: {
      ashpSpaceHeating: number;
      hybridTopUp: number;
      secondaryHeating: number;
      hotWater: number;
    }
    apr: {
      ashpSpaceHeating: number;
      hybridTopUp: number;
      secondaryHeating: number;
      hotWater: number;
    }
    may: {
      ashpSpaceHeating: number;
      hybridTopUp: number;
      secondaryHeating: number;
      hotWater: number;
    }
    june: {
      ashpSpaceHeating: number;
      hybridTopUp: number;
      secondaryHeating: number;
      hotWater: number;
    }
    july: {
      ashpSpaceHeating: number;
      hybridTopUp: number;
      secondaryHeating: number;
      hotWater: number;
    }
    aug: {
      ashpSpaceHeating: number;
      hybridTopUp: number;
      secondaryHeating: number;
      hotWater: number;
    }
    sept: {
      ashpSpaceHeating: number;
      hybridTopUp: number;
      secondaryHeating: number;
      hotWater: number;
    }
    oct: {
      ashpSpaceHeating: number;
      hybridTopUp: number;
      secondaryHeating: number;
      hotWater: number;
    }
    nov: {
      ashpSpaceHeating: number;
      hybridTopUp: number;
      secondaryHeating: number;
      hotWater: number;
    }
    dec: {
      ashpSpaceHeating: number;
      hybridTopUp: number;
      secondaryHeating: number;
      hotWater: number;
    }
  }
  totalCostByMonth: {
    jan: number;
    feb: number;
    march: number;
    apr: number;
    may: number;
    june: number;
    july: number;
    aug: number;
    sept: number;
    oct: number;
    nov: number;
    dec: number;
  }
}

export const ASHPPerformance: React.FC<Props> = props => {
  const componentRef = useRef<React.ReactInstance | null>(null);

  const handlePrint = useReactToPrint({
    copyStyles: true,
    pageStyle: `
        @page {
            /* Remove browser default header (title) and footer (url) */
            margin: 0;
        }
        @media print {
        @page {
            
        }
            body {
                /* Tell browsers to print background colors */
                -webkit-print-color-adjust: exact; /* Chrome/Safari/Edge/Opera */
                color-adjust: exact; /* Firefox */
            }
        }
    `,
    removeAfterPrint: false,
    suppressErrors: false,
    content: () => componentRef.current ? componentRef.current : null
  });

  // Combine both datasets into a single array
  const data = Object.keys(props.elecGenByMonth).map(month => ({
    month: month.charAt(0).toUpperCase() + month.slice(1),
    ...props.elecGenByMonth[ month ],
    totalCost: props.totalCostByMonth[ month.toLowerCase() ] // match the key case
  }));

  return (
    <Box>
      <Box>
        <Button
          variant="ghost"
          onClick={() => handlePrint()}
          leftIcon={<FaPrint />}
        >
          Print
        </Button>
      </Box>

      <Box>

        <Text
          fontWeight="semibold"
          fontSize="lg"
          p={4}
        >
          Air-source Heat Pump Performance Estimate - MCS and Genous Methodologies
        </Text>

        <Box ref={componentRef}>
          <Section>
            <Box
              background="black"
              p={4}
            >
              <Text
                fontWeight="bold"
                color="white"
              >Property details
              </Text>
            </Box>

            <Box>
              <LocalTable
                rows={[
                  {
                    values: [
                      "Valid EPC number",
                      props.validEPCNumber,
                      "Genous 'Draft EPC' estimates"
                    ]
                  },
                  {
                    values: [
                      "EPC space heating requirement",
                      `${props.epcSpaceHeatingRequirement}`,
                      `${props.epcSpaceHeatingRequirementOutputsC6.toFixed(0)}`
                    ]
                  },
                  {
                    values: [
                      "EPC hot water requirement",
                      `${props.epcHotWaterRequirement}`,
                      `${props.epcHotWaterRequirementOutputsC7.toFixed(0)}`
                    ]
                  }
                ]}
              />

              <LocalTable
                rows={[
                  { values: [ "Number of bedrooms", `${props.numOfBedrooms}` ] },
                  { values: [ "Number of people in property", `${props.numOfPeopleInProperty}` ] },
                  { values: [ "Location for design temperature", `${props.locationForDesignTemp}` ] },
                  { values: [ "Altitude (above sea level)", `${props.altitude}` ] }
                ]}
              />

              <LocalTable
                rows={[
                  { values: [ "Distance to assessment point", `${props.distanceToAssessmentPoint || ""}` ] },
                  { values: [ "# surfaces <1m from unit", `${props.surfacesFromUnit}` ] },
                  { values: [ "Shielding of ASHP", `${props.shieldingOfASHP}` ] }
                ]}
              />

            </Box>
          </Section>

          <Section>
            <Box
              background="black"
              p={4}
            >
              <Text
                fontWeight="bold"
                color="white"
              >Existing System
              </Text>
            </Box>

            <LocalTable
              headers={[
                "",
                "Manufacturer",
                "Model",
                "SAP description",
                "% Heat MCS",
                "% Heat Genous",
                "% efficiency",
                "Fuel",
                "Fuel price per kWh"
              ]}
              rows={[
                {
                  values: [
                    "Heating System 1",
                    `${props.heatingSystem1.manufacturer}`,
                    `${props.heatingSystem1.model}`,
                    `${props.heatingSystem1.sapDescription}`,
                    `${props.heatingSystem1.percentHeatMCS}`,
                    `${props.heatingSystem1.percentHeatGenous}`,
                    `${props.heatingSystem1.percentEfficiency}`,
                    `${props.heatingSystem1.fuel}`,
                    `${props.heatingSystem1.fuelPricePerkWh}`
                  ]
                },
                props.heatingSystem2 ? {
                  values: [
                    "Heating System 2",
                    `${props.heatingSystem2.manufacturer}`,
                    `${props.heatingSystem2.model}`,
                    `${props.heatingSystem2.sapDescription}`,
                    `${props.heatingSystem2.percentHeatMCS}`,
                    `${props.heatingSystem2.percentHeatGenous}`,
                    `${props.heatingSystem2.percentEfficiency}`,
                    `${props.heatingSystem2.fuel}`,
                    `${props.heatingSystem2.fuelPricePerkWh}`
                  ]
                } : { values: [] },
                props.secondaryHeatingSystem ? {
                  values: [
                    "Secondary heating system",
                    `${props.secondaryHeatingSystem.manufacturer}`,
                    `${props.secondaryHeatingSystem.model}`,
                    `${props.secondaryHeatingSystem.sapDescription}`,
                    `${props.secondaryHeatingSystem.percentHeatMCS}`,
                    `${props.secondaryHeatingSystem.percentHeatGenous}`,
                    `${props.secondaryHeatingSystem.percentEfficiency}`,
                    `${props.secondaryHeatingSystem.fuel}`,
                    `${props.secondaryHeatingSystem.fuelPricePerkWh}`
                  ]
                } : { values: [] },
                {
                  values: [
                    "Hot water system",
                    `${props.hotWaterSystem.manufacturer}`,
                    `${props.hotWaterSystem.model}`,
                    `${props.hotWaterSystem.sapDescription}`,
                    `${props.hotWaterSystem.percentHeatMCS}`,
                    `${props.hotWaterSystem.percentHeatGenous}`,
                    `${props.hotWaterSystem.percentEfficiency}`,
                    `${props.hotWaterSystem.fuel}`,
                    `${props.hotWaterSystem.fuelPricePerkWh}`
                  ]
                }
              ]}
            />

            <LocalTable
              headers={[]}
              rows={[ { values: [ "Hot water cylinder size", `${props.hotWaterCylinderSize}` ] } ]}
            />

          </Section>

          <Section>
            <Box
              background="black"
              p={4}
            >
              <Text
                fontWeight="bold"
                color="white"
              >Proposed system
              </Text>
            </Box>

            <Box display="inline-block">
              <Box>
                <LocalTable
                  headers={[
                    "",
                    "Manufacturer",
                    "Model Number",
                    "MCS Certification Number"
                  ]}
                  rows={[
                    {
                      values: [
                        "Heat pump selected",
                        `${props.heatPumpSelected.manufacturer}`,
                        `${props.heatPumpSelected.model}`,
                        `${props.heatPumpSelected.mcsCert}`
                      ]
                    }
                  ]}
                />
              </Box>

              <Box>
                <LocalTable
                  rows={[
                    { values: [ "Number of units", `${props.numOfUnits}` ] },
                    {
                      values: [
                        "Capacity of system",
                        `${props.capacityOfSystem}`,
                        "kW"
                      ]
                    },
                    { values: [ "Renewable heating coverage", `${props.renewableHeatingCoverage}` ] },
                    { values: [ "Pure HP/hybrid", `${props.pureHPHybrid}` ] }
                  ]}
                />
              </Box>

            </Box>

            <LocalTable
              rows={[
                { values: [ "Cylinder name", `${props.cylinderName}` ] },
                {
                  values: [
                    "Cylinder volume",
                    `${props.cylinderVolume}`,
                    "litres"
                  ]
                },
                {
                  values: [
                    "Cylinder minimum recommended volume per MCS",
                    `${props.cylinderMinMCSRecVolume}`,
                    "litres"
                  ]
                },
                {
                  values: [
                    "Cylinder coil size",
                    `${props.cylinderCoilSize}`,
                    "m2"
                  ]
                },
                {
                  values: [
                    "Immersion heater power",
                    `${props.immersionHeaterPower}`,
                    "kW"
                  ]
                }
              ]}
            />
          </Section>

          <Section>
            <Box
              background="black"
              p={4}
            >
              <Text
                fontWeight="bold"
                color="white"
              >Design Parameters
              </Text>
            </Box>

            <LocalTable
              headers={[
                "",
                "MCS",
                "Genous target"
              ]}
              rows={[
                {
                  values: [
                    "Target temperature in living area",
                    `${props.targetTempInLivingArea.mcs}`,
                    `${props.targetTempInLivingArea.genous}`,
                    "Degrees C"
                  ]
                },
                {
                  values: [
                    "Design temperature choice (%)",
                    `${props.designTempChoice.mcs}`,
                    `${props.designTempChoice.genous}`
                  ]
                },
                {
                  values: [
                    "Design temperature (ambient, corrected)",
                    `${props.designTempAmbient.mcs}`,
                    `${props.designTempAmbient.genous}`,
                    "Degrees C"
                  ]
                },
                {
                  values: [
                    "Hot water cylinder temperature",
                    `${props.hotWaterCylinderTemp.mcs}`,
                    `${props.hotWaterCylinderTemp.genous}`,
                    "Degrees C"
                  ]
                },
                {
                  values: [
                    "Flow Temperature - High",
                    `${props.flowTempHigh.mcs}`,
                    `${props.flowTempHigh.genous}`,
                    "Degrees C"
                  ]
                },
                {
                  values: [
                    "Flow Temperature - Low",
                    `${props.flowTempLow.mcs || "N/A"}`,
                    `${props.flowTempLow.genous}`,
                    "Degrees C"
                  ]
                },
                {
                  values: [
                    "Heating SCOP/equivalent at design temp",
                    `${Math.round(props.heatingSCOPEquivAtDesignTemp.mcs)}%`,
                    `${Math.round(props.heatingSCOPEquivAtDesignTemp.genous)}%`
                  ]
                },
                {
                  values: [
                    "Hot water SCOP/equivalent at design temp",
                    `${props.hotWaterSCOPEquivAtDesignTemp.mcs}%`,
                    `${props.hotWaterSCOPEquivAtDesignTemp.genous}%`
                  ]
                },
                {
                  values: [
                    "Ambient temperature heating starts at",
                    `${Math.round(props.ambientTempHeatingStartsAt.mcs) || "N/A"}`,
                    `${Math.round(props.ambientTempHeatingStartsAt.genous)}`,
                    "Degrees C"
                  ]
                }
              ]}
            />

            <LocalTable
              headers={[
                "",
                "Minutes before start",
                "Use per week"
              ]}
              rows={[
                {
                  values: [
                    "Immersion details",
                    `${props.immersionDetails.minutesBeforeStart}`,
                    `${props.immersionDetails.usePerWeek}`
                  ]
                }
              ]}
            />

          </Section>

          <Section>
            <Box
              background="black"
              p={4}
            >
              <Text
                fontWeight="bold"
                color="white"
              >Heating performance
              </Text>
            </Box>

            <LocalTable
              headers={[
                "",
                "MCS",
                "Genous"
              ]}
              rows={[
                {
                  values: [
                    "Renewable heat required for property (space heating)",
                    `${props.renewHeatRequiredForPropertySpaceHeating.mcs.toFixed(0)}`,
                    `${props.renewHeatRequiredForPropertySpaceHeating.genous.toFixed(0)}`,
                    "kWh"
                  ]
                },
                {
                  values: [
                    "Renewable heat required for property (hot water)",
                    `${props.renewHeatRequiredForPropertyHotWater.mcs.toFixed(0)}`,
                    `${props.renewHeatRequiredForPropertyHotWater.genous.toFixed(0)}`,
                    "kWh"
                  ]
                },
                {
                  values: [
                    "Renewable heat required (total)",
                    `${props.renewHeatRequiredForPropertyTotal.mcs.toFixed(0)}`,
                    `${props.renewHeatRequiredForPropertyTotal.genous.toFixed(0)}`,
                    "kWh"
                  ]
                },
                {
                  values: [
                    "Other energy supplied",
                    `${props.otherEnergySupplied.mcs.toFixed(0)}`,
                    `${props.otherEnergySupplied.genous.toFixed(0)}`,
                    "kWh"
                  ]
                },
                {
                  values: [
                    "Total energy assessed",
                    `${props.totalEnergyAssessed.mcs.toFixed(0)}`,
                    `${props.totalEnergyAssessed.genous.toFixed(0)}`,
                    "kWh"
                  ]
                },
                {
                  values: [
                    "Heated floor area",
                    `${props.heatedFloorArea.mcs.toFixed(0)}`,
                    `${props.heatedFloorArea.genous.toFixed(0)}`,
                    "m2"
                  ]
                },
                {
                  values: [
                    "Average peak heat loss per square metre",
                    `${props.averagePeakHeatLossPerSQM.mcs.toFixed(0)}`,
                    `${props.averagePeakHeatLossPerSQM.genous.toFixed(0)}`,
                    "W/m2"
                  ]
                },
                {
                  values: [
                    "Peak heat demand",
                    `${props.peakHeatDemand.mcs.toFixed(1)}`,
                    `${props.peakHeatDemand.genous.toFixed(1)}`,
                    "kW"
                  ]
                },
                {
                  values: [
                    "Hot water required per day",
                    `${props.hotWaterRequiredPerDay.mcs.toFixed(0)}`,
                    `${props.hotWaterRequiredPerDay.genous.toFixed(0)}`,
                    "litres"
                  ]
                },
                {
                  values: [
                    "Hot water recovery time - no immersion",
                    `${props.hotWaterRecoveryTimeNoImmersion.mcs.toFixed(0)}`,
                    `${props.hotWaterRecoveryTimeNoImmersion.genous.toFixed(0)}`,
                    "minutes"
                  ]
                },
                {
                  values: [
                    "Hot water recovery time - with immersion",
                    `${props.hotWaterRecoveryTimeImmersion.mcs.toFixed(0)}`,
                    `${props.hotWaterRecoveryTimeImmersion.genous.toFixed(0)}`,
                    "minutes"
                  ]
                }
              ]}
            />

            <LocalTable
              headers={[
                "",
                "Existing (MCS)",
                "Proposed (MCS)",
                "Difference (MCS)",
                "Existing (Genous)",
                "Proposed (Genous)",
                "Difference (Genous)",
                ""
              ]}
              rows={[
                {
                  values: [
                    "Energy used - space heating",
                    `${props.energyUsedSpaceHeating.mcs.existing?.toFixed(0) || ""}`,
                    `${props.energyUsedSpaceHeating.mcs.proposed?.toFixed(0) || ""}`,
                    `${props.energyUsedSpaceHeating.mcs.diff?.toFixed(0) || ""}`,
                    `${props.energyUsedSpaceHeating.genous.existing?.toFixed(0) || ""}`,
                    `${props.energyUsedSpaceHeating.genous.proposed?.toFixed(0) || ""}`,
                    `${props.energyUsedSpaceHeating.genous.diff?.toFixed(0 || "")}`,
                    "kWh"
                  ]

                },
                {
                  values: [
                    "Energy used - hot water",
                    `${props.energyUsedHotWater.mcs.existing?.toFixed(0) || ""}`,
                    `${props.energyUsedHotWater.mcs.proposed?.toFixed(0) || ""}`,
                    `${props.energyUsedHotWater.mcs.diff?.toFixed(0) || ""}`,
                    `${props.energyUsedHotWater.genous.existing?.toFixed(0) || ""}`,
                    `${props.energyUsedHotWater.genous.proposed?.toFixed(0) || ""}`,
                    `${props.energyUsedHotWater.genous.diff?.toFixed(0) || ""}`,
                    "kWh"
                  ]

                },
                {
                  values: [
                    "Energy used - total",
                    `${props.energyUsedTotal.mcs.existing?.toFixed(0) || ""}`,
                    `${props.energyUsedTotal.mcs.proposed?.toFixed(0) || ""}`,
                    `${props.energyUsedTotal.mcs.diff?.toFixed(0) || ""}`,
                    `${props.energyUsedTotal.genous.existing?.toFixed(0) || ""}`,
                    `${props.energyUsedTotal.genous.proposed?.toFixed(0) || ""}`,
                    `${props.energyUsedTotal.genous.diff?.toFixed(0) || ""}`,
                    "kWh"
                  ]
                }
              ]}
            />

          </Section>

          <Section>
            <Box
              background="black"
              p={4}
            >
              <Text
                fontWeight="bold"
                color="white"
              >Financial and carbon performance
              </Text>
            </Box>

            <LocalTable
              rows={[
                { values: [ "Installation cost (gross)", `£${props.installationCostGross.toFixed(0)}` ] },
                { values: [ "Boiler Upgrade Scheme Grant (if applicable)", `${props.boilerUpgradeScheme}` ] },
                { values: [ "Net installation cost", `£${props.netInstallationCost.toFixed(0)}` ] },
                {
                  values: [
                    "Time period to evaluate (max 30yrs)",
                    `${props.timePeriodToEvaluate}`,
                    "years"
                  ]
                },
                {
                  values: [
                    "Annual electricity price rise assumed",
                    `${props.annualElecPriceRiseAssumed}`,
                    "% p.a."
                  ]
                },
                {
                  values: [
                    "Annual fossil fuel price rise assumed",
                    `${props.annualFossilFuelPriceRiseAssumed}`,
                    "% p.a."
                  ]
                }
              ]}
            />

            <LocalTable
              headers={[
                "",
                "MCS",
                "Genous"
              ]}
              rows={[
                {
                  values: [
                    "Annual run cost savings estimate",
                    `£${props.annualRunCostSavingsEstimate.mcs.toFixed(0)}`,
                    `£${props.annualRunCostSavingsEstimate.genous.toFixed(0)}`
                  ]
                },
                {
                  values: [
                    "Annual return (%)",
                    `${props.annualReturnPercent.mcs.toFixed(0)}%`,
                    `${props.annualReturnPercent.genous.toFixed(0)}%`
                  ]
                },
                {
                  values: [
                    "Annual CO2e reduction",
                    `${props.annualCO2eReduction.mcs > 0 ? props.annualCO2eReduction.mcs.toFixed(1) : "N/A"}`,
                    `${props.annualCO2eReduction.genous.toFixed(1) || "N/A"}`,
                    "Tonnes p.a."
                  ]
                },
                {
                  values: [
                    "Total period run cost savings",
                    `£${props.totalPeriodRunCostSavings.mcs.toFixed(0)}`,
                    `£${props.totalPeriodRunCostSavings.genous.toFixed(0)}`
                  ]
                },
                {
                  values: [
                    "Implied payback period	",
                    `${props.impliedPaybackPeriod.mcs > 30 ? "Outside time period" : props.impliedPaybackPeriod.mcs.toFixed(0)}`,
                    `${props.impliedPaybackPeriod.genous > 30 ? "Outside time period" : props.impliedPaybackPeriod.genous.toFixed(0)}`,
                    "Years"
                  ]
                },
                {
                  values: [
                    "Total returns / expenditure",
                    `${(props.totalReturnsExpenditure.mcs * 100).toFixed(0)}%`,
                    `${(props.totalReturnsExpenditure.genous * 100).toFixed(0)}%`
                  ]
                },
                {
                  values: [
                    "Total period CO2e avoided",
                    `${props.totalPeriodCO2eAvoided.mcs > 0 ? props.totalPeriodCO2eAvoided.mcs.toFixed(0) : "N/A"}`,
                    `${props.totalPeriodCO2eAvoided.genous.toFixed(0) || "N/A"}`,
                    "Tonnes"
                  ]
                }
              ]}
            />
          </Section>

          <Section>
            <Box
              background="black"
              p={4}
            >
              <Text
                fontWeight="bold"
                color="white"
              >Room-by-room details
              </Text>
            </Box>

            <LocalTable
              headers={[
                "",
                "Specific heat loss w/m2",
                "Existing emitters",
                "To be removed?",
                "New emitters",
                "Temperature achieved",
                "Agreed temperature target",
                "Pass/fail"
              ]}
              rows={props.rooms.map(room => ({
                values: [
                  room.title,
                  room.specificHeatLossWM2.toFixed(0),
                  room.existingEmitters,
                  room.toBeRemoved,
                  room.newEmitters,
                  room.tempAchieved.toFixed(0),
                  room.agreedTempTarget.toString(),
                  {
                    value: room.passFail,
                    color: room.passFail === "PASS" ? "green" : "red"
                  }
                ]
              }))}
            />

            <LocalTable
              headers={[]}
              rows={[ { values: [ "Worst performing room", props.worstPerformingRoom ] } ]}
            />
          </Section>

          <Section>
            <Box
              background="black"
              p={4}
            >
              <Text
                fontWeight="bold"
                color="white"
              >Compliance Details
              </Text>
            </Box>

            <LocalTable
              headers={[
                "",
                "MCS",
                "Pass/Fail"
              ]}
              rows={[
                {
                  values: [
                    "Corrected sound pressure level (dB)",
                    `${props.correctedSoundPressureLevels.mcs.toFixed(1)}`,
                    `${props.correctedSoundPressureLevels.genous}`
                  ]
                },
                {
                  values: [
                    "All room temperatures sufficient or approved?",
                    `${props.roomByRoomTempApproved.mcs}`,
                    `${props.roomByRoomTempApproved.genous}`
                  ]
                },
                {
                  values: [
                    "Amount of heat load HP can provide (%)",
                    props.amountOfHeatLoadHPCanProvide.mcs.toFixed(0),
                    props.amountOfHeatLoadHPCanProvide.genous
                  ]
                },
                {
                  values: [
                    "Amount of annual heating required HP will provide (%)",
                    props.amountAnnualHeatingRequiredHPProvide.mcs.toFixed(0),
                    props.amountAnnualHeatingRequiredHPProvide.genous
                  ]
                },
                {
                  values: [
                    "Amount of annual heating hybrid will provide (%)",
                    props.amountOfAnnualHeatingHybridWillProvide.mcs.toFixed(0),
                    props.amountOfAnnualHeatingHybridWillProvide.genous
                  ]
                },
                {
                  values: [
                    "Is hot water covered by HP (or electric already)?",
                    props.isHotWaterCoveredByHPORElectricAlready.mcs.toString(),
                    props.isHotWaterCoveredByHPORElectricAlready.genous
                  ]
                },
                {
                  values: [
                    "Is a valid EPC in place consistent with MCS rules?",
                    props.isValidEPCPlaceConsistentMCSRules.mcs.toString(),
                    props.isValidEPCPlaceConsistentMCSRules.genous
                  ]
                }

              ]}
            />

            <LocalTable
              headers={[]}
              rows={[ { values: [ "Compliant install - BUS", props.compliantInstallBus ] }, { values: [ "Compliant install - no BUS", props.compliantInstallNoBus ] } ]}
            />
          </Section>

          <Section>
            <Box
              background="black"
              p={4}
            >
              <Text
                fontWeight="bold"
                color="white"
              >Monthly future performance and cost estimate (excluding any new PV/battery benefits, Genous approach only)
              </Text>
            </Box>

            <ResponsiveContainer
              width="100%"
              height={400}
            >
              <ComposedChart
                data={data}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />

                <XAxis dataKey="month" />

                {/* Left Y-Axis */}
                <YAxis
                  type="number"
                  yAxisId="left"
                  label={{
                    value: "kWh",
                    angle: -90,
                    position: "insideLeft",
                    offset: -5
                  }}
                />

                {/* Right Y-Axis */}
                <YAxis
                  type="number"
                  yAxisId="right"
                  domain={[ "auto", "auto" ]}
                  orientation="right"
                  label={{
                    value: "Cost",
                    angle: 90,
                    position: "insideRight",
                    offset: -5
                  }}
                />

                <Legend formatter={formatLegendLabel} />

                <Bar
                  dataKey="ashpSpaceHeating"
                  stackId="a"
                  fill="#3A86FF"
                  yAxisId="left"
                >
                  <LabelList
                    dataKey="ashpSpaceHeating"
                    position="center"
                    fill="black"

                  />
                </Bar>

                <Bar
                  dataKey="hybridTopUp"
                  stackId="a"
                  fill="#FF006E"
                  yAxisId="left"
                >
                  <LabelList
                    dataKey="hybridTopUp"
                    fill="black"

                    position="center"
                  />
                </Bar>

                <Bar
                  dataKey="secondaryHeating"
                  stackId="a"
                  fill="#FFBE0B"
                  yAxisId="left"
                >
                  <LabelList
                    dataKey="secondaryHeating"
                    position="center"
                    fill="black"

                  />
                </Bar>

                <Bar
                  dataKey="hotWater"
                  stackId="a"
                  fill="#3ECF8E"

                  yAxisId="left"
                >
                  <LabelList
                    fill="black"
                    dataKey="hotWater"
                    position="center"
                  />
                </Bar>

                {/* Line for total cost */}
                <Line
                  type="monotone"
                  dataKey="totalCost"
                  stroke="#ae07f5"
                  yAxisId="right"
                  strokeWidth={2}
                  dot={{ r: 3 }}
                />

              </ComposedChart>
            </ResponsiveContainer>

          </Section>
        </Box>
      </Box>
    </Box>
  );
};

export const ASHPPerfTest = () => {
  const props: Props = {
    elecGenByMonth: {
      jan: {
        ashpSpaceHeating: 400,
        hybridTopUp: 80,
        secondaryHeating: 60,
        hotWater: 40
      },
      feb: {
        ashpSpaceHeating: 100,
        hybridTopUp: 70,
        secondaryHeating: 50,
        hotWater: 30
      },
      march: {
        ashpSpaceHeating: 120,
        hybridTopUp: 80,
        secondaryHeating: 60,
        hotWater: 40
      },
      apr: {
        ashpSpaceHeating: 100,
        hybridTopUp: 70,
        secondaryHeating: 50,
        hotWater: 30
      },
      may: {
        ashpSpaceHeating: 120,
        hybridTopUp: 80,
        secondaryHeating: 60,
        hotWater: 40
      },
      june: {
        ashpSpaceHeating: 100,
        hybridTopUp: 70,
        secondaryHeating: 50,
        hotWater: 30
      },
      july: {
        ashpSpaceHeating: 120,
        hybridTopUp: 80,
        secondaryHeating: 60,
        hotWater: 40
      },
      aug: {
        ashpSpaceHeating: 100,
        hybridTopUp: 70,
        secondaryHeating: 50,
        hotWater: 30
      },
      sept: {
        ashpSpaceHeating: 120,
        hybridTopUp: 80,
        secondaryHeating: 60,
        hotWater: 40
      },
      oct: {
        ashpSpaceHeating: 100,
        hybridTopUp: 70,
        secondaryHeating: 50,
        hotWater: 30
      },
      nov: {
        ashpSpaceHeating: 120,
        hybridTopUp: 80,
        secondaryHeating: 60,
        hotWater: 40
      },
      dec: {
        ashpSpaceHeating: 100,
        hybridTopUp: 70,
        secondaryHeating: 50,
        hotWater: 30
      }
    },
    totalCostByMonth: {
      jan: 300,
      feb: 250,
      march: 900,
      apr: 350,
      may: 450,
      june: 500,
      july: 480,
      aug: 470,
      sept: 450,
      oct: 430,
      nov: 400,
      dec: 390
    },
    installationCostGross: 22,
    impliedPaybackPeriod: {
      mcs: 22,
      genous: 22
    },
    boilerUpgradeScheme: 22,
    netInstallationCost: 22,
    timePeriodToEvaluate: 22,
    annualCO2eReduction: {
      mcs: 22,
      genous: 22
    },
    rooms: [
      {
        title: "bedroom",
        specificHeatLossWM2: 2,
        existingEmitters: "xx",
        toBeRemoved: "ye",
        newEmitters: "ee",
        tempAchieved: 21,
        agreedTempTarget: 22,
        passFail: "pass"
      }
    ],
    annualElecPriceRiseAssumed: 2,
    annualFossilFuelPriceRiseAssumed: 2,
    annualRunCostSavingsEstimate: {
      mcs: 2,
      genous: 4
    },
    annualReturnPercent: {
      mcs: 2,
      genous: 22
    },
    totalPeriodCO2eAvoided: {
      mcs: 22,
      genous: 21
    },
    worstPerformingRoom: "dd",
    cylinderName: "xy",
    totalPeriodRunCostSavings: {
      mcs: 22,
      genous: 22
    },
    totalReturnsExpenditure: {
      mcs: 22,
      genous: 21
    },
    cylinderCoilSize: "ss",
    cylinderVolume: "ss",
    cylinderMinMCSRecVolume: "mon",
    immersionHeaterPower: "s",
    targetTempInLivingArea: {
      mcs: 2,
      genous: 22
    },
    designTempAmbient: {
      mcs: 2,
      genous: 22
    },
    designTempChoice: {
      genous: 22,
      mcs: 21
    },
    hotWaterCylinderTemp: {
      mcs: 22,
      genous: 21
    },
    renewHeatRequiredForPropertyHotWater: {
      mcs: 21,
      genous: 22
    },
    renewHeatRequiredForPropertyTotal: {
      mcs: 22,
      genous: 21
    },
    totalEnergyAssessed: {
      mcs: 21,
      genous: 22
    },
    otherEnergySupplied: {
      mcs: 22,
      genous: 21
    },
    averagePeakHeatLossPerSQM: {
      mcs: 21,
      genous: 22
    },
    energyUsedTotal: {
      genous: {
        existing: 22,
        proposed: 21,
        diff: 2
      },
      mcs: {
        existing: 22,
        proposed: 21,
        diff: 3
      }
    },
    energyUsedSpaceHeating: {
      genous: {
        existing: 22,
        proposed: 21,
        diff: 2
      },
      mcs: {
        existing: 22,
        proposed: 21,
        diff: 3
      }
    },
    energyUsedHotWater: {
      genous: {
        existing: 22,
        proposed: 21,
        diff: 2
      },
      mcs: {
        existing: 22,
        proposed: 21,
        diff: 3
      }
    },
    peakHeatDemand: {
      mcs: 22,
      genous: 21
    },
    flowTempHigh: {
      mcs: 22,
      genous: 21
    },
    flowTempLow: {
      genous: 22,
      mcs: 22
    },
    heatedFloorArea: {
      mcs: 22,
      genous: 21
    },
    immersionDetails: {
      minutesBeforeStart: 22,
      usePerWeek: 22
    },
    heatingSCOPEquivAtDesignTemp: {
      mcs: 22,
      genous: 21
    },
    hotWaterRecoveryTimeImmersion: {
      mcs: 22,
      genous: 21
    },
    hotWaterRecoveryTimeNoImmersion: {
      mcs: 22,
      genous: 21
    },
    hotWaterSCOPEquivAtDesignTemp: {
      genous: 21,
      mcs: 22
    },
    ambientTempHeatingStartsAt: {
      mcs: 22,
      genous: 21
    },
    renewHeatRequiredForPropertySpaceHeating: {
      genous: 21,
      mcs: 23
    },
    heatPumpSelected: {
      manufacturer: "man",
      model: "model",
      mcsCert: "cert"
    },
    numOfUnits: "2",
    hotWaterCylinderSize: "2",
    capacityOfSystem: "capacity",
    renewableHeatingCoverage: "renewbale",
    pureHPHybrid: "pure",
    heatingSystem1: {
      manufacturer: "man",
      model: "model",
      sapDescription: "descriptipn",
      percentEfficiency: "90",
      percentHeatGenous: "22",
      percentHeatMCS: "44",
      fuel: "ss",
      fuelPricePerkWh: "22"
    },
    heatingSystem2: {
      manufacturer: "man",
      model: "model",
      sapDescription: "descriptipn",
      percentEfficiency: "90",
      percentHeatGenous: "22",
      percentHeatMCS: "44",
      fuel: "ss",
      fuelPricePerkWh: "22"
    },
    hotWaterSystem: {
      manufacturer: "man",
      model: "model",
      sapDescription: "descriptipn",
      percentEfficiency: "90",
      percentHeatGenous: "22",
      percentHeatMCS: "44",
      fuel: "ss",
      fuelPricePerkWh: "22"
    },
    secondaryHeatingSystem: {
      manufacturer: "man",
      model: "model",
      sapDescription: "descriptipn",
      percentEfficiency: "90",
      percentHeatGenous: "22",
      percentHeatMCS: "44",
      fuel: "ss",
      fuelPricePerkWh: "22"
    },
    validEPCNumber: 60,
    epcHotWaterRequirement: "sdas",
    epcHotWaterRequirementOutputsC7: 213,
    numOfBedrooms: 4,
    epcSpaceHeatingRequirement: "Requirement",
    epcSpaceHeatingRequirementOutputsC6: 44,
    numOfPeopleInProperty: 2,
    locationForDesignTemp: 21,
    altitude: 100,
    distanceToAssessmentPoint: 3,
    surfacesFromUnit: 2,
    shieldingOfASHP: "shield",
    correctedSoundPressureLevels: {
      mcs: 22,
      genous: 21
    },
    roomByRoomTempApproved: {
      mcs: 22,
      genous: 21
    },
    amountAnnualHeatingRequiredHPProvide: {
      mcs: 21,
      genous: 22
    },
    amountOfAnnualHeatingHybridWillProvide: {
      mcs: 2,
      genous: 22
    },
    amountOfHeatLoadHPCanProvide: {
      mcs: 22,
      genous: 21
    },
    isHotWaterCoveredByHPORElectricAlready: {
      mcs: 22,
      genous: 21
    },
    isValidEPCPlaceConsistentMCSRules: {
      mcs: 21,
      genous: 21
    },
    compliantInstallBus: "PASS",
    compliantInstallNoBus: "PASS"
  };

  return <ASHPPerformance {...props} />;
};

const LocalTable = ({ rows, headers = [] }: { headers?: string[], rows: Array<{values: Array<{value: string; color: string} | string>}>}) => {
  return (
    <Box
      display='inline-block'
      verticalAlign="top"
    >
      <TableContainer
        shadow="md"
        height="100%"
        border="1px solid #d0d4db"
        m={6}
      >
        <Table
          variant="striped"
          width="auto"
        >
          <Thead>
            <Tr>
              {headers.map((header, index) => (
                <Th
                  key={index}
                  whiteSpace="nowrap"
                >
                  {header}
                </Th>
              ))}
            </Tr>
          </Thead>

          <Tbody>
            {rows.map((row, rowIndex) => (
              <Tr key={rowIndex}>
                {row.values.map((cell, cellIndex) => (
                  <Td
                    key={cellIndex}
                    background={typeof cell === "string" ? "" : cell.color + " !important"}
                  >
                    <Text
                      color="black"
                      maxWidth="200px" // Set your desired max width
                      whiteSpace="normal"
                      overflowWrap="break-word"
                    >
                      {typeof cell === "string" ? cell : cell.value}
                    </Text>
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

const Section = ({ children }: {children: React.ReactChild}) => {
  return (
    <Box padding={2}>
      {children}
    </Box>
  );
};

const formatLegendLabel = value => {
  switch (value) {
    case "ashpSpaceHeating":
      return "ASHP Space Heating";
    case "hybridTopUp":
      return "Hybrid Top-Up (if used)";
    case "secondaryHeating":
      return "Secondary Heating (if used)";
    case "hotWater":
      return "Hot Water";
    case "totalCost":
      return "Total Cost";
    default:
      return value;
  }
};