import {
  HStack,
  Box,
  Button,
  Image,
  List,
  ListItem,
  ListIcon
} from "@chakra-ui/react";
import React from "react";
import { desktopBreakpointKey } from "src/hooks/useIsDesktop";
import { Wrapper } from "src/components/shared";
import { Text } from "src/components/text";
import theme from "src/components/theme";
import screenshots from "src/assets/images/third-party/demo.png";
import { CheckCircleIcon } from "@chakra-ui/icons";

export const BestInClassSection: React.FC = () => (
  <Wrapper
    py={{
      base: theme.spacing[ 12 ],
      [ desktopBreakpointKey ]: theme.spacing[ 24 ]
    }}
    minH={{
      base: "auto",
      [ desktopBreakpointKey ]: "100vh"
    }}
    display="flex"
    alignItems="center"
  >
    <HStack
      alignItems="flex-start"
      spacing={{
        base: 4,
        [ desktopBreakpointKey ]: 8
      }}
      color="black"
      flexDirection={{
        base: "column",
        [ desktopBreakpointKey ]: "row"
      }}
      flexWrap={{
        base: "wrap",
        [ desktopBreakpointKey ]: "nowrap"
      }}
    >
      <Box
        width={{
          base: "100%",
          xl: "50%"
        }}
      >
        <Text
          fontWeight="medium"
          mb={2}
          fontSize={{
            base: "lg",
            [ desktopBreakpointKey ]: "xl"
          }}
        >
          We offer
        </Text>

        <List
          spacing={2}
          w="full"
          fontSize={{
            base: "md",
            [ desktopBreakpointKey ]: "lg"
          }}
          whiteSpace={{
            base: "normal",
            [ desktopBreakpointKey ]: "nowrap"
          }}
          flexBasis={{
            base: "100%",
            [ desktopBreakpointKey ]: "40%"
          }}
          lineHeight="1.4"
          pr={{
            base: 0,
            [ desktopBreakpointKey ]: "5%"
          }}
          mb={{
            base: theme.spacing[ 6 ],
            [ desktopBreakpointKey ]: 0
          }}
        >
          {[
            "Easy-to-use automated tools",
            "Comprehensive support from real experts",
            "Buy direct from us, under MCS/RECC/Trustmark",
            "Heat pumps, solar, insulation, EV chargers and more",
            "Only the best products on a 'whole of market' basis"
          ].map((item, index) => (
            <ListItem
              key={index}
              display="flex"
              alignItems="start"
            >
              <ListIcon
                as={CheckCircleIcon}
                color="blue.500"
                mt={1}
                mr={2}
              />

              <Text color="grey.700">{item}</Text>
            </ListItem>
          ))}
        </List>

      </Box>

      <Box
        display={{
          base: "none",
          xl: "block"
        }}
        position="relative"
        bottom={0}
        right={-20}
        width={{
          base: "125%",
          lg: "125%"
        }}
        mt={{
          base: 4,
          lg: 0
        }}
      >
        <Image
          position="relative"
          src={screenshots}
        />
      </Box>
    </HStack>
  </Wrapper>
);