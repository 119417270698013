import { EPC } from ".";
import { useAxios } from "../../hooks/useAxios";
import { PostcodeLookupResponse } from "../../postcode-lookup/postcode-lookup";
import { JSONAPIResponse } from "../json-api-response";

// request + response types

interface PropertySearchParams {
  addrLine1?: string;
  postcode: string;
}

interface PropertyPathParams {
  propertyId: number;
}

type PropertiesEndpointConfig = {
GET_POSTCODE_LOOKUP_RESULTS: EndpointConfig<
    PropertySearchParams,
    JSONAPIResponse<PostcodeLookupResponse>
>;
GET_EPC_FOR_PROPERTY: EndpointConfig<
    PropertyPathParams,
    JSONAPIResponse<EPC[]>
>;
GET_EPC_BY_POSTCODE: EndpointConfig<
  PropertySearchParams,
    JSONAPIResponse<EPC[]>
>;
GET_ALL_PROPERTIES: EndpointConfig<
    {page: number},
        JSONAPIResponse<{count: number, rows: Property[], pageSize: number}>
    >;
SEARCH_PROPERTIES: EndpointConfig<
    {query: string, page?: number},
    JSONAPIResponse<{count: number, rows: Property[], pageSize: number}>
>;
};

// users api specific logic

const requestInitialisers: RequestConfig<PropertiesEndpointConfig> = {
  GET_POSTCODE_LOOKUP_RESULTS: (
    ({ postcode }) => ({
      url: `/properties/search?postcode=${postcode}`,
      method: "GET",
      unauthenticated: true
    })
  ),
  GET_EPC_FOR_PROPERTY: (
    ({ propertyId }) => ({
      url: `/properties/${propertyId}/epc`,
      method: "GET",
      unauthenticated: true
    })),
  GET_EPC_BY_POSTCODE: (
    ({ postcode, addrLine1 }) => ({
      url: `/epc/postcode?postcode=${postcode}&addrLine1=${addrLine1}`,
      method: "GET",
      unauthenticated: true
    })
  ),
  SEARCH_PROPERTIES: (
    (data: { query: string, page?: number }) => ({
      url: "/properties/search",
      method: "POST",
      data
    })
  ),
  GET_ALL_PROPERTIES: (
    ({ page = 1 }) => ({
      url: "/properties/get-all",
      method: "GET",
      params: { page }
    })
  )
};

export const usePropertiesApi: UseApiRequest<PropertiesEndpointConfig> = name => useAxios(requestInitialisers[ name ]);
