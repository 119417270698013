import styled from "styled-components";
import { Link } from "react-router-dom";
import { theme } from "src/components/theme";
import {
  Box,
  Button,
  Fade
} from "@chakra-ui/react";

import { routes } from "src/constants";

import { Text } from "../text";

const gradient = `${theme.colors.green [ 50 ]}, ${theme.colors.blue[ 800 ]}`;

export const ImpactReviewButton: React.FC<{delay?: number}> = ({ delay }) => {
  return (
    <Fade
      in
      delay={delay}
      style={{ transitionDuration: "2s" }}
    />
  );
};

const Wrapper = styled.div`
  position: fixed;
  bottom: 5%;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  padding: ${theme.spacing[ 5 ]};
  z-index: 100;
`;
