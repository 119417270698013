import {
  Box,
  ComponentWithAs,
  ChakraProps
} from "@chakra-ui/react";
import { breakpoints } from "src/components/theme/breakpoints";
import { useBreakpointKeyValue } from "src/hooks/useBreakpointKeyValue";
import { getValueFromBreakpoint } from "src/hooks/useResponsiveBreakpointValue";

import theme from "../theme";

export type WrapperVariant = keyof typeof wrapperSizes;

type ResponseValue<T> = T | Partial<Record<keyof typeof breakpoints, T>>;

const FALLBACK_VARIANT: WrapperVariant = "layout";

export const Wrapper: ComponentWithAs<"div", ChakraProps & {variant?: ResponseValue<WrapperVariant>}> = ({ variant = FALLBACK_VARIANT, ...restProps }) => {
  const [ currentBreakpointKey, currentBreakpointValue ] = useBreakpointKeyValue();
  const isResponsive = typeof variant !== "string";
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const responsiveVariant = isResponsive ? getValueFromBreakpoint(variant, currentBreakpointKey, currentBreakpointValue!) : variant;

  return (
    <Box
      w="100%"
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      maxW={wrapperSizes[ responsiveVariant! ]}
      px={responsiveVariant === "full" ? 0 : {
        base: theme.spacing[ 2 ],
        xl: 0
      }}
      mx="auto"
      flex={1}
      data-testid="wrapper"
      {...restProps}
    />
  );
};

// define a minimum of 'base'
export const wrapperSizes = {
  full: { base: "100%" },
  layout: {
    "base": "100%",
    "sm": "95%",
    "md": "85%",
    "lg": "1150px",
    "xl": "1200px",
    "2xl": "1400px"
  },
  narrow: {
    base: "100%",
    sm: "95%",
    md: "85%",
    lg: "940px"
  },
  progress: {
    base: "100%",
    sm: "95%",
    md: "60ch"
  },
  text: {
    base: "100%",
    sm: "95%",
    md: "60ch"
  },
  form: {
    base: "100%",
    sm: "40ch"
  }
} as const;