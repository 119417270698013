import { Box } from "@chakra-ui/react";

export const NavContainer: React.FC = ({ children }) => {
  return (
    <Box
      as="nav"
      zIndex={9}
      h="60px"
      shadow='sm'
    >
      {children}
    </Box>
  );
};